exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-house-style-js": () => import("./../../../src/pages/about/house-style.js" /* webpackChunkName: "component---src-pages-about-house-style-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-masthead-js": () => import("./../../../src/pages/about/masthead.js" /* webpackChunkName: "component---src-pages-about-masthead-js" */),
  "component---src-pages-about-submissions-js": () => import("./../../../src/pages/about/submissions.js" /* webpackChunkName: "component---src-pages-about-submissions-js" */),
  "component---src-pages-about-testimonials-js": () => import("./../../../src/pages/about/testimonials.js" /* webpackChunkName: "component---src-pages-about-testimonials-js" */),
  "component---src-pages-addenda-food-pasts-food-futures-js": () => import("./../../../src/pages/addenda/food-pasts-food-futures.js" /* webpackChunkName: "component---src-pages-addenda-food-pasts-food-futures-js" */),
  "component---src-pages-best-of-the-pdr-js": () => import("./../../../src/pages/best-of-the-pdr.js" /* webpackChunkName: "component---src-pages-best-of-the-pdr-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-complete-your-sign-up-js": () => import("./../../../src/pages/complete-your-sign-up.js" /* webpackChunkName: "component---src-pages-complete-your-sign-up-js" */),
  "component---src-pages-complete-your-signup-js": () => import("./../../../src/pages/complete-your-signup.js" /* webpackChunkName: "component---src-pages-complete-your-signup-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contributors-js": () => import("./../../../src/pages/contributors.js" /* webpackChunkName: "component---src-pages-contributors-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-essays-js": () => import("./../../../src/pages/essays.js" /* webpackChunkName: "component---src-pages-essays-js" */),
  "component---src-pages-explore-indexed-js": () => import("./../../../src/pages/explore/indexed.js" /* webpackChunkName: "component---src-pages-explore-indexed-js" */),
  "component---src-pages-explore-js": () => import("./../../../src/pages/explore.js" /* webpackChunkName: "component---src-pages-explore-js" */),
  "component---src-pages-features-entering-the-public-domain-2022-js": () => import("./../../../src/pages/features/entering-the-public-domain/2022.js" /* webpackChunkName: "component---src-pages-features-entering-the-public-domain-2022-js" */),
  "component---src-pages-features-entering-the-public-domain-2023-js": () => import("./../../../src/pages/features/entering-the-public-domain/2023.js" /* webpackChunkName: "component---src-pages-features-entering-the-public-domain-2023-js" */),
  "component---src-pages-features-entering-the-public-domain-2024-js": () => import("./../../../src/pages/features/entering-the-public-domain/2024.js" /* webpackChunkName: "component---src-pages-features-entering-the-public-domain-2024-js" */),
  "component---src-pages-guide-to-finding-interesting-public-domain-works-online-js": () => import("./../../../src/pages/guide-to-finding-interesting-public-domain-works-online.js" /* webpackChunkName: "component---src-pages-guide-to-finding-interesting-public-domain-works-online-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pd-remix-animated-gifs-js": () => import("./../../../src/pages/pd-remix/animated-gifs.js" /* webpackChunkName: "component---src-pages-pd-remix-animated-gifs-js" */),
  "component---src-pages-pd-remix-caption-competition-js": () => import("./../../../src/pages/pd-remix/caption-competition.js" /* webpackChunkName: "component---src-pages-pd-remix-caption-competition-js" */),
  "component---src-pages-pd-remix-js": () => import("./../../../src/pages/pd-remix.js" /* webpackChunkName: "component---src-pages-pd-remix-js" */),
  "component---src-pages-postcards-js": () => import("./../../../src/pages/postcards.js" /* webpackChunkName: "component---src-pages-postcards-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-returns-policy-js": () => import("./../../../src/pages/returns-policy.js" /* webpackChunkName: "component---src-pages-returns-policy-js" */),
  "component---src-pages-reusing-material-js": () => import("./../../../src/pages/reusing-material.js" /* webpackChunkName: "component---src-pages-reusing-material-js" */),
  "component---src-pages-rights-labelling-on-our-site-js": () => import("./../../../src/pages/rights-labelling-on-our-site.js" /* webpackChunkName: "component---src-pages-rights-labelling-on-our-site-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-series-conjectures-js": () => import("./../../../src/pages/series/conjectures.js" /* webpackChunkName: "component---src-pages-series-conjectures-js" */),
  "component---src-pages-series-curators-choice-js": () => import("./../../../src/pages/series/curators-choice.js" /* webpackChunkName: "component---src-pages-series-curators-choice-js" */),
  "component---src-pages-shop-about-our-prints-js": () => import("./../../../src/pages/shop/about-our-prints.js" /* webpackChunkName: "component---src-pages-shop-about-our-prints-js" */),
  "component---src-pages-shop-affinities-js": () => import("./../../../src/pages/shop/affinities.js" /* webpackChunkName: "component---src-pages-shop-affinities-js" */),
  "component---src-pages-shop-delivery-estimates-js": () => import("./../../../src/pages/shop/delivery-estimates.js" /* webpackChunkName: "component---src-pages-shop-delivery-estimates-js" */),
  "component---src-pages-shop-faq-js": () => import("./../../../src/pages/shop-faq.js" /* webpackChunkName: "component---src-pages-shop-faq-js" */),
  "component---src-pages-shop-fine-art-prints-js": () => import("./../../../src/pages/shop/fine-art-prints.js" /* webpackChunkName: "component---src-pages-shop-fine-art-prints-js" */),
  "component---src-pages-shop-gift-cards-js": () => import("./../../../src/pages/shop/gift-cards.js" /* webpackChunkName: "component---src-pages-shop-gift-cards-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-shop-search-js": () => import("./../../../src/pages/shop/search.js" /* webpackChunkName: "component---src-pages-shop-search-js" */),
  "component---src-pages-sign-up-success-js": () => import("./../../../src/pages/sign-up-success.js" /* webpackChunkName: "component---src-pages-sign-up-success-js" */),
  "component---src-pages-sources-js": () => import("./../../../src/pages/sources.js" /* webpackChunkName: "component---src-pages-sources-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-support-become-a-friend-js": () => import("./../../../src/pages/support/become-a-friend.js" /* webpackChunkName: "component---src-pages-support-become-a-friend-js" */),
  "component---src-pages-support-donate-js": () => import("./../../../src/pages/support/donate.js" /* webpackChunkName: "component---src-pages-support-donate-js" */),
  "component---src-pages-support-friends-js": () => import("./../../../src/pages/support/friends.js" /* webpackChunkName: "component---src-pages-support-friends-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-what-is-the-public-domain-js": () => import("./../../../src/pages/what-is-the-public-domain.js" /* webpackChunkName: "component---src-pages-what-is-the-public-domain-js" */),
  "component---src-templates-best-of-js": () => import("./../../../src/templates/best-of.js" /* webpackChunkName: "component---src-templates-best-of-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-collections-source-listing-js": () => import("./../../../src/templates/collections/source-listing.js" /* webpackChunkName: "component---src-templates-collections-source-listing-js" */),
  "component---src-templates-collections-taxonomy-js": () => import("./../../../src/templates/collections/taxonomy.js" /* webpackChunkName: "component---src-templates-collections-taxonomy-js" */),
  "component---src-templates-contributor-js": () => import("./../../../src/templates/contributor.js" /* webpackChunkName: "component---src-templates-contributor-js" */),
  "component---src-templates-essay-js": () => import("./../../../src/templates/essay.js" /* webpackChunkName: "component---src-templates-essay-js" */),
  "component---src-templates-essays-listing-js": () => import("./../../../src/templates/essays/listing.js" /* webpackChunkName: "component---src-templates-essays-listing-js" */),
  "component---src-templates-further-reading-js": () => import("./../../../src/templates/further-reading.js" /* webpackChunkName: "component---src-templates-further-reading-js" */),
  "component---src-templates-pd-text-js": () => import("./../../../src/templates/pd-text.js" /* webpackChunkName: "component---src-templates-pd-text-js" */),
  "component---src-templates-shop-product-category-js": () => import("./../../../src/templates/shop/product-category.js" /* webpackChunkName: "component---src-templates-shop-product-category-js" */),
  "component---src-templates-shop-product-view-js": () => import("./../../../src/templates/shop/product-view.js" /* webpackChunkName: "component---src-templates-shop-product-view-js" */),
  "component---src-templates-shop-random-print-viewer-js": () => import("./../../../src/templates/shop/random-print-viewer.js" /* webpackChunkName: "component---src-templates-shop-random-print-viewer-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}


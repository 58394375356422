exports.onRouteUpdate = ({location}) => {
  anchorScroll(location);
  return true;
};
exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  anchorScroll(location);
  return true;
}

function anchorScroll(location){
  // Check for location so build does not fail
  if (location && location.hash && location.hash.substring(1, 2) !== '/') {
    setTimeout(() => {
      const item = document.querySelector(`${location.hash}`);
      let boundingEl = item;
      let offset = 0;
      const mainNav = document.querySelector('.site-header');

      if (item) {
        if (mainNav) {
          while (boundingEl.offsetParent) {
            offset += boundingEl.offsetTop;
            boundingEl = boundingEl.offsetParent;
          }
          window.scrollTo({ top: offset - mainNav.offsetHeight - 16, left: 0, behavior: 'smooth' })
        } else {
          item.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }, 0);
  }
}
